import { render, staticRenderFns } from "./SectionPortfolioMap.vue?vue&type=template&id=396c1712&scoped=true&"
import script from "./SectionPortfolioMap.vue?vue&type=script&lang=js&"
export * from "./SectionPortfolioMap.vue?vue&type=script&lang=js&"
import style0 from "./SectionPortfolioMap.vue?vue&type=style&index=0&id=396c1712&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396c1712",
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionPortfolioMap.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Flanding%2FSectionPortfolioMap.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,OrganismsPortfolioOrganismProjectPortfolio: require('/app/components/organisms/portfolio/OrganismProjectPortfolio.vue').default})
